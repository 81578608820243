import { customerText } from "../Seo/whitelabel/bybest/bybest-customer-layout";

const statusStyles = {
  success: {
    textColor: "text-[#027A48]",
    bgColor: "bg-[#ECFDF3]",
  },
  error: {
    textColor: "text-[#FF3E3E]",
    bgColor: "bg-[#FDEFEC]",
  },
  info: {
    textColor: "text-[#3E45FF]",
    bgColor: "bg-[#EDEEFF]",
  },
  default: {
    textColor: "text-[#344054]",
    bgColor: "bg-[#F2F4F7]",
  },
};

const statusesWithSuccessStyle = [
  "completed",
  "joined",
  "earned",
  "active",
  "delivered",
  customerText("completed").toLowerCase(),
  customerText("joined").toLowerCase(),
  customerText("earned").toLowerCase(),
  customerText("active").toLowerCase(),
  customerText("delivered").toLowerCase(),
];
const statusesWithErrorStyle = [
  "cancelled",
  "unavailable",
  "no active",
  "spent",
  customerText("cancelled").toLowerCase(),
  customerText("unavailble").toLowerCase(),
  customerText("noActive").toLowerCase(),
  customerText("spent").toLowerCase(),
];
const statusesWithInfoStyle = [
  "confirmed",
  customerText("confirmed").toLowerCase(),
];

export const getStatus = (status = "") => {
  const lowerStatus = status.toLocaleLowerCase();

  let style = statusStyles.default;

  if (statusesWithSuccessStyle.includes(lowerStatus)) {
    style = statusStyles.success;
  } else if (statusesWithErrorStyle.includes(lowerStatus)) {
    style = statusStyles.error;
  } else if (statusesWithInfoStyle.includes(lowerStatus)) {
    style = statusStyles.info;
  }

  return (
    <div>
      <div
        className={`flex ${style.textColor} ${style.bgColor} px-2 py-[2px] rounded-[16px] text-12 font-medium leading-[18px]`}
      >
        <span className="text-[20px] content-center mr-1">•</span>
        {status}
      </div>
    </div>
  );
};
