import React, { useState } from "react";
import { customerText } from "../../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import ActionBtns from "../action-btns";
import EmojiPickerModal from "./emoji-picker";
import "../index.css";

const FooterChatSection = ({
  text,
  setText,
  handleSendMessage,
  colorTheme = "#cb0000",
}) => {
  const [image, setImage] = useState(null);
  const [emoji, setEmoji] = useState(null);

  const handleInputChange = (event) => setText(event.target.value);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && text.trim()) {
      handleSendMessage();
    }
  };

  return (
    <div className="flex items-center w-full">
      <ActionBtns onImageSelected={(item) => setImage(item)} />
      <div className="flex-grow relative mx-2">
        <input
          value={text}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Type a message"
          className="rounded-[12px] border outline-none w-full h-12 px-5"
        />

        <EmojiPickerModal
          onSelect={(item) => {
            setEmoji(item.emoji);
            setText((text) => text + item.emoji);
          }}
        />
      </div>
      <div className="flex items-center md:w-48">
        <button
          className={`rounded-lg w-full font-bold bg-[${colorTheme}] hover:bg-[${colorTheme}] flex h-12 justify-center items-center text-white px-4 py-2 ${
            text.length === 0 ? "cursor-not-allowed opacity-60" : ""
          }`}
          onClick={handleSendMessage}
          disabled={text.length === 0}
        >
          {customerText("send")}
        </button>
      </div>
    </div>
  );
};

export default FooterChatSection;
