import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import BBApartments from "../../assets/images/bbApartments.png";
import { changeLanguage } from "../../redux/actions/app";
import "./index.css";
import Sidebar from "../../components/Sidebar";
import { getAccommodationLinks } from "../../components/Sidebar/data";
import { decodePathname } from "../../views/accommodation-customer/login";
import { useMatchMedia } from "../../services/hooks/use-match-media";
import menuIcon from "../../assets/svgs/bybest/menu.svg";
import { Drawer } from "antd";
import { customerText } from "../../components/Seo/whitelabel/bybest/bybest-customer-layout";

const AccommodationHeader = ({ isAuth }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const language = useSelector((state) => state.app.accommodationLanguage);
  const [open, setOpen] = useState(false);

  const isMobile = useMatchMedia();

  useEffect(() => {
    if (!isMobile) setOpen(true);
  }, [isMobile]);

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    dispatch(changeLanguage(newLanguage));
    window.location.reload();
  };

  const headerLinks = [
    {
      to: `/about`,
      label: customerText("about"),
    },
    {
      to: `/contact`,
      label: customerText("contact"),
    },
  ];

  const selectedNavLInk = decodePathname(pathname).replace(
    `/accommodation/customer/`,
    ""
  );

  const sideBar = (
    <Sidebar
      open
      navLinks={getAccommodationLinks}
      selected={selectedNavLInk}
      showTier={false}
      prefix={`/accommodation`}
      containerClassNames={`!left-0 !top-0 !border-t-0 !mt-20 flex flex-col`}
      ativeLinkClassNames="!bg-[#6DDFE2]"
      tokenName={"accToken"}
      redirectOnLogout={"customer/personal-info"}
    />
  );

  return (
    <div className="mt-20 bg-white ">
      <div className="fixed top-0 w-full !h-20 bg-white py-3 flex justify-between px-6 z-[100] border-b-2">
        {isAuth && isMobile && (
          <div
            className="flex justify-center items-center"
            onClick={() => setOpen(!open)}
          >
            <img alt="" src={menuIcon} />
          </div>
        )}
        <Link to="/">
          <img
            alt="bybestlogo"
            src={BBApartments}
            className="h-[52px] object-contain"
          />
        </Link>

        <div className="flex items-center justify-end gap-2">
          {headerLinks.map((link, index) => (
            <div key={index} className={`my-club relative`}>
              {
                <Link to={link.to}>
                  <span>{link.label}</span>
                </Link>
              }
            </div>
          ))}
          <div className="gap-0 flex items-center bg-[#efefef]">
            <select
              className="cursor-pointer bg-transparent outline-none border-none"
              value={language}
              onChange={handleLanguageChange}
            >
              <option value="al">AL</option>
              <option value="en">EN</option>
            </select>
          </div>
        </div>
      </div>

      {!isMobile ? (
        <>{isAuth && sideBar}</>
      ) : (
        <Drawer
          placement="left"
          closable={false}
          onClose={() => {
            setOpen(false);
          }}
          open={open}
          width="253px"
          mask={true}
          zIndex={5}
          bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
        >
          {sideBar}
        </Drawer>
      )}
    </div>
  );
};

export default AccommodationHeader;
