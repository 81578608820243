import React from "react";
import PromotionSummary from "./promotion-summary";
import CustomCalendar from "./calendar";
import "./index.css";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

const PromotionCalendar = ({ promoClassName, promoCalendarClassName }) => {
  return (
    <div className="px-[15px] w-full boxshadow border-[1px] border-[#EAECF0] rounded-[8px] bg-white flex flex-col justify-between">
      <div className="w-full">
        <CustomCalendar promoCalendarClassName={promoCalendarClassName} />
      </div>

      <div className="flex flex-col">
        <PromotionSummary
          title="VILLEROY20"
          description={`24.57% ${customerText("expiresOn")} 12/08/2024`}
          promo={`15% ${customerText("promoOff")}`}
          promoClassName={promoClassName}
        />

        <hr className="border-[1px] border-[#E5E7EB]" />

        <PromotionSummary
          title="bbvrtc707hv"
          description={`24.57% ${customerText("expiresOn")} 12/08/2024`}
          promo={`20% ${customerText("promoOff")}`}
          promoClassName={promoClassName}
        />
      </div>
    </div>
  );
};

export default PromotionCalendar;
