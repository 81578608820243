import { useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/actions/auth";

export const useLogout = (
  isLogout = false,
  tokenName = "vbToken",
  redirectPath = "/login"
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    if (isLogout) {
      dispatch(logout())
        .then((data) => {
          NotificationManager.success(
            data?.message || "Logout Success",
            "Success",
            5000
          );
          navigate(`/login`);
        })
        .catch((error) => {
          if (error?.message?.includes("Token has expired")) {
            navigate(`/login`);
          }

          NotificationManager.error(
            error.message ||
              "Oops! Something went wrong. Please try again or contact us directly at contact@venueboost.io.",
            "Error",
            3000
          );
          throw new Error("Error with logout");
        });
    }

    localStorage.removeItem(tokenName);
    localStorage.removeItem("USER_DATA");
    navigate(redirectPath);
  };
  return handleLogout;
};
