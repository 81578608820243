import React, { useState } from "react";
import CustomerHeader from "../customer-header";
import { walletColumns, walletData } from "./columns";
import GenericTable from "../../../components/Table/generic-table";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import { getStatus } from "../../../components/columns/get-status";

const WalletTable = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    total: walletData.length,
  });

  const walletColumns = [
    {
      title: customerText("type"),
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <p className="text-14 text-[#667085] leading-5 font-medium">{type}</p>
      ),
    },
    {
      title: customerText("date"),
      dataIndex: "date",
      key: "date",
      render: (date) => (
        <p className="text-14 text-[#667085] leading-5 font-medium">{date}</p>
      ),
    },
    {
      title: customerText("points"),
      dataIndex: "points",
      key: "points",
      render: (points) => (
        <p className="text-14 text-[#240b3b] leading-5 font-bold">{points}</p>
      ),
    },
    {
      title: customerText("description"),
      dataIndex: "description",
      key: "description",
      render: (value) => (
        <p className="text-14 text-[#667085] leading-5 font-medium">{value}</p>
      ),
    },
    {
      title: customerText("status"),
      dataIndex: "status",
      key: "status",
      render: (value) => getStatus(customerText(value)),
    },
  ];

  const handlePageChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
    }));
  };
  return (
    <div className="flex flex-1 flex-col mt-6">
      <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
        <div className="flex justify-between items-center w-full p-6">
          <CustomerHeader
            description={customerText("orderKeepTrack")}
            numberOfItems={walletData.length}
            items={customerText("activities")}
            title={customerText("walletActivities")}
            showHome={false}
          />
        </div>
        <GenericTable
          columns={walletColumns}
          data={walletData.slice(
            (pagination.current - 1) * 10,
            pagination.current * 10
          )}
          className="w-full hidden md:block pr-4"
          pagination={{
            ...pagination,
            onChange: handlePageChange,
          }}
        />
      </div>
    </div>
  );
};

export default WalletTable;
