import React from "react";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

const GeneralInfo = ({ data, handleChange, containerClassNames }) => {
  return (
    <div
      className={`bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg ${containerClassNames}`}
    >
      <div className="p-6 gap-6 md:gap-2 flex flex-col">
        <div className="text-[#101828] text-18 font-semibold leading-6 md:leading-7">
          {customerText("generalInfo")}
        </div>
        <div className="w-full flex flex-col md:flex-row md:items-center">
          <div className="w-full">
            <p className="text-14 text-[#374151] font-semibold leading-5">
              {customerText("firstName")}
            </p>
          </div>
          <div className="w-full">
            <input
              type="text"
              name="first_name"
              value={data.first_name || ""}
              onChange={handleChange}
              className="w-full border-[1px] border-[#D1D5DB] rounded-[6px] px-[13px] py-[9px] text-14 font-medium leading-5 focus:border-blue-400"
            />
          </div>
        </div>
        <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5 " />
        <div className="w-full flex flex-col md:flex-row md:items-center">
          <div className="w-full">
            <p className="text-14 text-[#374151] font-semibold leading-5">
              {customerText("lastName")}
            </p>
          </div>
          <div className="w-full">
            <input
              type="text"
              name="last_name"
              value={data.last_name || ""}
              onChange={handleChange}
              className="w-full border-[1px] border-[#D1D5DB] rounded-[6px] px-[13px] py-[9px] text-14 font-medium leading-5 focus:border-blue-400"
            />
          </div>
        </div>
        <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5 " />
        <div className="w-full flex flex-col md:flex-row md:items-center">
          <div className="w-full">
            <p className="text-14 text-[#374151] font-semibold leading-5">
              {customerText("emailAddress")}
            </p>
          </div>
          <div className="w-full">
            <input
              type="text"
              name="email"
              value={data.email || ""}
              onChange={handleChange}
              className="w-full border-[1px] border-[#D1D5DB] rounded-[6px] px-[13px] py-[9px] text-14 font-medium leading-5 focus:border-blue-400"
            />
          </div>
        </div>
        <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5 " />
        <div className="w-full flex flex-col md:flex-row md:items-center">
          <div className="w-full">
            <p className="text-14 text-[#374151] font-semibold leading-5">
              {customerText("phoneNumber")}
            </p>
          </div>
          <div className="w-full">
            <input
              type="text"
              name="phone"
              value={data.phone || ""}
              onChange={handleChange}
              className="w-full border-[1px] border-[#D1D5DB] rounded-[6px] px-[13px] py-[9px] text-14 font-medium leading-5 focus:border-blue-400"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
