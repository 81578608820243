import React from "react";
import { Country } from "country-state-city";
import { Icon } from "react-icons-kit";
import { chevronDown } from "react-icons-kit/feather/chevronDown";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

const Address = ({ data, handleChange, containerClassNames }) => {
  return (
    <div
      className={`bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg ${containerClassNames}`}
    >
      <div className="p-6 gap-6 md:gap-2 flex flex-col">
        <div className="text-[#101828] text-18 font-semibold leading-6 md:leading-7">
          {customerText("address")}
        </div>

        <div className="w-full flex flex-col md:flex-row md:items-center">
          <div className="w-full">
            <p className="text-14 text-[#374151] font-semibold leading-5">
              {customerText("country")}
            </p>
          </div>
          <div className="w-full">
            <div className="relative h-[38px] border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400 ">
              <select
                name="country"
                value={data.country || ""}
                onChange={handleChange}
                placeholder={customerText("enterCountry")}
                className="flex-1 appearance-none h-[36px] rounded-[5px] w-full"
              >
                {Country.getAllCountries().map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>

              <span
                className="flex absolute justify-around items-center right-5"
                style={{ color: "#CBD5E1" }}
              >
                <Icon className="absolute" icon={chevronDown} size={18} />
              </span>
            </div>
          </div>
        </div>

        <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5" />

        <div className="w-full flex flex-col md:flex-row md:items-center">
          <div className="w-full">
            <p className="text-14 text-[#374151] font-semibold leading-5">
              {customerText("streetAddress")}
            </p>
          </div>
          <div className="w-full">
            <input
              type="text"
              name="street_address"
              value={data.street_address || ""}
              onChange={handleChange}
              className="w-full border-[1px] border-[#D1D5DB] rounded-[6px] px-[13px] py-[9px] text-14 font-medium leading-5 focus:border-blue-400"
            />
          </div>
        </div>

        <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5" />

        <div className="w-full flex flex-col md:flex-row md:items-center">
          <div className="w-full">
            <p className="text-14 text-[#374151] font-semibold leading-5">
              {customerText("city")}
            </p>
          </div>
          <div className="w-full">
            <input
              type="text"
              name="city"
              value={data.city || ""}
              onChange={handleChange}
              className="w-full border-[1px] border-[#D1D5DB] rounded-[6px] px-[13px] py-[9px] text-14 font-medium leading-5 focus:border-blue-400"
            />
          </div>
        </div>

        <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5" />

        <div className="w-full flex flex-col md:flex-row md:items-center">
          <div className="w-full">
            <p className="text-14 text-[#374151] font-semibold leading-5">
              {customerText("state")}
            </p>
          </div>
          <div className="w-full">
            <input
              type="text"
              name="state"
              value={data.state || ""}
              onChange={handleChange}
              className="w-full border-[1px] border-[#D1D5DB] rounded-[6px] px-[13px] py-[9px] text-14 font-medium leading-5 focus:border-blue-400"
            />
          </div>
        </div>

        <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5" />

        <div className="w-full flex flex-col md:flex-row md:items-center">
          <div className="w-full">
            <p className="text-14 text-[#374151] font-semibold leading-5">
              {customerText("zip")}
            </p>
          </div>
          <div className="w-full">
            <input
              type="text"
              name="zip"
              value={data.zip || ""}
              onChange={handleChange}
              className="w-full border-[1px] border-[#D1D5DB] rounded-[6px] px-[13px] py-[9px] text-14 font-medium leading-5 focus:border-blue-400"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
