import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ChatModal from "../../../customer/chat/chat-modal";
import CustomerHeader from "../../../customer/customer-header";
import { customerText } from "../../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import { getStatus } from "../../../../components/columns/get-status";
import { chatIcon } from "../../../customer/orders/icons";
import { ReactComponent as BookIcon } from "../../../../assets/svgs/book.svg";
import {
  calendarIcon,
  giftIcon,
  handMoney,
  keyIcon,
  locationIcon,
  recieptIcon,
  refundIcon,
  userIcon,
} from "./icons";
import BBApartments from "../../../../assets/images/bbApartments.png";
import cardImg from "../../../../assets/images/payment-cards/Mastercard.png";
import "./index.css";

const AccBookingDetails = () => {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);

  const CardComponent = ({ title, icon, body }) => {
    return (
      <div className="flex flex-col gap-1 w-full border shadow-md rounded-lg p-6">
        <div className="flex gap-3 font-semibold">
          <span>{icon}</span>
          <span>{title}</span>
        </div>
        <div className="ml-7 flex flex-col gap-1">{body}</div>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg">
      <div className="flex flex-1 flex-col">
        <div className="w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
          <div className="grid md:flex justify-between items-center w-full border p-6">
            <CustomerHeader
              breadCrumb={
                <>
                  <p className="text-[#6B7280] text-20 font-medium leading-5">{`>`}</p>
                  <p className="text-[#6B7280] text-14 font-medium leading-5">
                    {`${customerText("bookings")} > ${state.name}`}
                  </p>
                </>
              }
              title={
                <div className="flex flex-col sm:flex-row gap-3 sm:items-center">
                  <span>{customerText("your_acc_booking")}</span>
                  <div className="status-card ">
                    <span className="text-sm">65151651</span>
                    <span>{getStatus(customerText(state?.status))}</span>
                  </div>
                </div>
              }
              showItems={false}
            />
            <div className="flex flex-col gap-5 items-start md:items-end w-full">
              <div className="flex items-center gap-2">
                <div className="status-card cursor-pointer">
                  <BookIcon />
                  <span>{customerText("rebook_appartment")}</span>
                </div>
                <div
                  className="status-card cursor-pointer"
                  onClick={() => setOpen(true)}
                >
                  <span>{chatIcon}</span>
                  <span>{customerText("chatNow")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 flex flex-col sm:flex-row gap-4 items-center">
            <div className="text-[#101828] text-18 font-semibold leading-7">
              {state.name}
            </div>
            <div className="flex flex-col sm:flex-row gap-3 items-center">
              <div className="flex gap-3 items-center">
                {locationIcon("#6DDFE2")}
                <span>Rruga Sami Frasheri, Tirana Albania, 1001</span>
              </div>

              <span className="text-[#6DDFE2]">
                {customerText("get_direction")}
              </span>
            </div>
          </div>

          <div className="grid sm:grid-cols-2 gap-6 p-6 !pt-0 text-sm">
            {[
              {
                icon: calendarIcon("#6DDFE2"),
                title: `${customerText(
                  "dates"
                )}: Tue, 16 Jul 2024 - Wed, 17 Jul 2024`,
                body: (
                  <>
                    <span>Check-in: 15:00 - 21:00</span>
                    <span>Check-out: 07:00 - 11:00</span>
                  </>
                ),
              },
              {
                icon: keyIcon("#6DDFE2"),
                title: customerText("check_in_info"),
                body: (
                  <>
                    <span>{customerText("info_from_host")}</span>

                    <span className="text-[#6DDFE2]">
                      {customerText("view_details")}
                    </span>
                  </>
                ),
              },
            ].map((item) => (
              <CardComponent {...item} />
            ))}
          </div>

          <div className="text-[#101828] text-18 font-semibold leading-7 p-6 pt-0">
            {customerText("booking_details")}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 p-6 !pt-0 text-sm">
            {[
              {
                icon: userIcon("#6DDFE2"),
                title: customerText("nr_of_guests"),
                body: `2 ${customerText("guests")}`,
              },
              {
                icon: giftIcon("#6DDFE2"),
                title: customerText("benefits"),
                body: customerText("benefits_description"),
              },
            ].map((item) => (
              <CardComponent {...item} />
            ))}
            <div className="sm:col-span-2">
              <CardComponent
                icon={refundIcon("#6DDFE2")}
                title={customerText("partially_refundable")}
                body={customerText("refund_policy")}
              />
            </div>
          </div>

          <div className="text-[#101828] text-18 font-semibold leading-7 p-6 pt-0">
            {customerText("payment_details")}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 p-6 !pt-0 text-sm">
            {[
              {
                icon: userIcon("#6DDFE2"),
                title: customerText("total_amount"),
                body: (
                  <div className="flex gap-2">
                    <span className="text-2xl font-semibold">€513.68</span>
                    <span className="px-2 py-1 font-bold text-sm flex items-center rounded-full bg-[#E148481C] text-[#E14848]">
                      {customerText("discount_label")}
                    </span>
                  </div>
                ),
              },
              {
                icon: recieptIcon("#6DDFE2"),
                title: customerText("paid_with_card"),
                body: (
                  <div className="flex gap-2 items-center border rounded-md px-4 py-2 w-fit">
                    <img src={cardImg} />
                    &#x2022; &#x2022; &#x2022;
                    &#x2022;&#x2022;&#x2022;&#x2022;&#x2022; 6543
                  </div>
                ),
              },
            ].map((item) => (
              <CardComponent {...item} />
            ))}
          </div>

          <div className="p-6 pt-0">
            <div className="bg-[#6DDFE236] p-6 rounded-[12px] border border-[#6DDFE2]">
              <p>{customerText("points_earned")}</p>
            </div>
          </div>
          <div className="p-6 pt-0">
            <CardComponent
              icon={handMoney("#6DDFE2")}
              title={customerText("prepayment")}
              body={customerText("prepayment_description")}
            />
          </div>
          <ChatModal
            handleClose={() => setOpen(false)}
            open={open}
            iconUrl={BBApartments}
            colorTheme={"#6DDFE2"}
          />
        </div>
      </div>
    </div>
  );
};

export default AccBookingDetails;
