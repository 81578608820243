import React, { useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "../../customer/promotions/index.css";
import PromotionCalendar from "../../customer/promotions/promotion-calendar";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerHeader from "../../customer/customer-header";
import GenericTable from "../../../components/Table/generic-table";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useGetPromotionsData } from "./data";

const AccPromotions = () => {
  const {
    currentPromotionsData,
    currentPromotionsColumns,
    usedPromotionsData,
    usedPromotionsColumns,
  } = useGetPromotionsData();
  const initialPagination = {
    current: 1,
    pageSize: 5,
    total: currentPromotionsData.length,
  };
  const [currentPromotion, setCurrentPromotion] = useState(initialPagination);

  const [usedPromotion, setUsedPromotion] = useState({
    current: 1,
    pageSize: 5,
    total: usedPromotionsData.length,
  });

  const handleCurrentPageChange = (page, pageSize) => {
    setCurrentPromotion((prev) => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  const handleUsedPageChange = (page, pageSize) => {
    setUsedPromotion((prev) => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  return (
    <div className="bg-white w-full grid lg:grid-cols-3 grid-cols-1 gap-4 p-6 rounded-lg">
      <div className="col-span-2 w-full border rounded-[12px]">
        <div className="flex justify-between items-center w-full p-6">
          <CustomerHeader
            description={
              <span className="flex items-center gap-3">
                <span>{customerText("keep_track_active_promotions")}</span>
                <ArrowForwardIcon />
              </span>
            }
            numberOfItems={currentPromotionsData.length}
            items={customerText("promotions")}
            title={customerText("currentPromotions")}
          />
        </div>
        <GenericTable
          columns={currentPromotionsColumns}
          data={currentPromotionsData.slice(
            (currentPromotion.current - 1) * currentPromotion.pageSize,
            currentPromotion.current * currentPromotion.pageSize
          )}
          className="w-full md:block pr-4"
          pagination={{
            ...currentPromotion,
            onChange: handleCurrentPageChange,
          }}
        />
      </div>

      <PromotionCalendar
        promoClassName={"!bg-[#6DDFE2] !text-white"}
        promoCalendarClassName="promo2"
      />

      <div className="col-span-2 w-full border rounded-[12px]">
        <div className="flex justify-between items-center w-full p-6">
          <CustomerHeader
            showHome={false}
            numberOfItems={usedPromotionsData.length}
            items={customerText("promotions")}
            title={customerText("usedPromotions")}
          />
        </div>
        <GenericTable
          columns={usedPromotionsColumns}
          data={usedPromotionsData.slice(
            (usedPromotion.current - 1) * usedPromotion.pageSize,
            usedPromotion.current * usedPromotion.pageSize
          )}
          className="w-full md:block pr-4"
          pagination={{
            ...usedPromotion,
            onChange: handleUsedPageChange,
          }}
        />
      </div>
    </div>
  );
};

export default AccPromotions;
