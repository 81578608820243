import { getStatus } from "../../../components/columns/get-status";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

export const walletData = [
  {
    key: "1",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "2",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-155",
    description: "No note",
    status: "spent",
  },
  {
    key: "3",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "4",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-132",
    description: "No note",
    status: "spent",
  },
  {
    key: "11",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "21",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-155",
    description: "No note",
    status: "spent",
  },
  {
    key: "31",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "41",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-132",
    description: "No note",
    status: "spent",
  },
  {
    key: "12",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "22",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-155",
    description: "No note",
    status: "spent",
  },
  {
    key: "32",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "42",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-132",
    description: "No note",
    status: "spent",
  },
  {
    key: "13",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "23",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-155",
    description: "No note",
    status: "spent",
  },
  {
    key: "33",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "43",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-132",
    description: "No note",
    status: "spent",
  },
  {
    key: "15",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "25",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-155",
    description: "No note",
    status: "spent",
  },
  {
    key: "35",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "45",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-132",
    description: "No note",
    status: "spent",
  },
  {
    key: "16",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "26",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-155",
    description: "No note",
    status: "spent",
  },
  {
    key: "36",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "46",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-132",
    description: "No note",
    status: "spent",
  },
  {
    key: "17",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "27",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-155",
    description: "No note",
    status: "spent",
  },
  {
    key: "37",
    type: "Credit",
    date: "15/10/2023 16:00 PM",
    points: "+5",
    description: "Referral bonus",
    status: "earned",
  },
  {
    key: "47",
    type: "Debit",
    date: "15/10/2023 16:00 PM",
    points: "-132",
    description: "No note",
    status: "spent",
  },
];

export const referralsColumns = [
  {
    title: customerText("type"),
    dataIndex: "type",
    key: "type",
    render: (value) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{value}</p>
    ),
  },
  {
    title: customerText("dateJoined"),
    dataIndex: "dateJoined",
    key: "dateJoined",
    render: (value) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{value}</p>
    ),
  },
  {
    title: customerText("status"),
    dataIndex: "status",
    key: "status",
    render: getStatus,
  },
];

export const referralsData = [
  {
    key: "1",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "2",
    type: "Nico Merris",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "3",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("pending"),
  },
  {
    key: "1",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "2",
    type: "Nico Merris",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "3",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("pending"),
  },
  {
    key: "1",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "2",
    type: "Nico Merris",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "3",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("pending"),
  },
  {
    key: "1",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "2",
    type: "Nico Merris",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "3",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("pending"),
  },
  {
    key: "11",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "22",
    type: "Nico Merris",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
];
