import React from "react";
import { useSelector } from "react-redux";
import Avatar from "../../assets/svgs/alex_r_avatar.svg";
import { useLogout } from "../../services/hooks/use-logout";
import { customerText } from "../Seo/whitelabel/bybest/bybest-customer-layout";
import { appKey, links } from "./data";
import { logoutIcon } from "./icons";
import "./index.css";
import { SidebarLink } from "./sidebar-link";
import TierLevel from "./tier-level";

const Sidebar = ({
  selected,
  open = false,
  setOpen,
  navLinks,
  showTier = true,
  containerClassNames,
  ativeLinkClassNames,
  prefix,
  tokenName,
  redirectOnLogout,
}) => {
  const handleLogout = useLogout(false, tokenName, redirectOnLogout);
  const user = useSelector((state) => state?.auth?.user);
  return (
    <div
      style={{ height: "calc(100vh - 80px)" }}
      className={`sidebar overflow-y-auto lg:w-[290px] bg-white md:flex flex-col ${
        open ? "fixed top-28 left-2 bg-white h-screen z-50" : "hidden"
      } ${containerClassNames ?? ""}`}
    >
      <div className="user-profile mt-2">
        <img alt="" src={Avatar} className="w-16 h-16" />
        <div className="user-name">
          <h1>{user?.name}</h1>
          <p className="text-[#666A73] text-14 ">BB Points: 200</p>
        </div>
      </div>

      {showTier && <TierLevel className="w-full p-4 mt-4" />}

      <div
        className={`flex flex-col h-full gap-5 justify-between ${
          open ? "" : "flex-1"
        }  w-full`}
      >
        <div className="sidebar-item-list w-full">
          {(navLinks ? navLinks(selected) : links(selected)).map((link) => (
            <SidebarLink
              {...link}
              linkKey={link.key}
              selected={selected === link.key}
              setOpen={setOpen}
              ativeLinkClassNames={ativeLinkClassNames}
              prefix={prefix}
            />
          ))}
        </div>

        <div className="px-4">
          <SidebarLink
            icon={logoutIcon}
            label={customerText("sidebar.signout")}
            onItemClick={handleLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
