import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import Place1 from "../../../assets/images/about-ap/place1.png";
import Place2 from "../../../assets/images/about-ap/place2.png";
import Place3 from "../../../assets/images/about-ap/place3.png";
import { getStatus } from "../../../components/columns/get-status";

export const useGetPromotionsData = () => {
  const currentPromotionsColumns = [
    {
      title: customerText("apartment"),
      dataIndex: "apartment",
      key: "apartment",
      render: (apartment) => (
        <div className="flex items-center gap-2">
          <img
            src={apartment.image}
            alt={apartment.name}
            className="w-12 h-12 rounded-lg object-cover"
          />
          <p className="text-14 text-[#101828] font-medium">{apartment.name}</p>
        </div>
      ),
    },
    {
      title: customerText("description"),
      dataIndex: "description",
      key: "description",
      render: (description) => (
        <p className="text-14 text-[#667085] font-medium">{description}</p>
      ),
    },
    {
      title: customerText("expiryDate"),
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (expiryDate) => (
        <p className="text-14 text-[#667085] font-medium">{expiryDate}</p>
      ),
    },
    {
      title: customerText("promo"),
      dataIndex: "promo",
      key: "promo",
      render: (promo) => (
        <p className="text-14 text-[#101828] font-medium">{promo}</p>
      ),
    },
    {
      title: customerText("status"),
      dataIndex: "status",
      key: "status",
      render: (value) => getStatus(customerText(value)),
    },
  ];

  const currentPromotionsData = [
    {
      key: "1",
      apartment: { name: "Apartment 9B", image: Place1 },
      description: "3 Nights + 1 Free",
      expiryDate: "15/10/2024",
      promo: "9B20OFF",
      status: "active",
    },
    {
      key: "2",
      apartment: { name: "Apartment 9A", image: Place2 },
      description: "10% OFF First Booking",
      expiryDate: "15/10/2024",
      promo: "9A30OFF",
      status: "active",
    },
    {
      key: "3",
      apartment: { name: "Apartment 8A", image: Place3 },
      description: "10% OFF First Booking",
      expiryDate: "15/10/2024",
      promo: "8AAA8AAA",
      status: "active",
    },
  ];

  const usedPromotionsColumns = [
    {
      title: customerText("apartment"),
      dataIndex: "apartment",
      key: "apartment",
      render: (apartment) => (
        <div className="flex items-center gap-2">
          <img
            src={apartment.image}
            alt={apartment.name}
            className="w-12 h-12 rounded-lg object-cover"
          />
          <p className="text-14 text-[#101828] font-medium">{apartment.name}</p>
        </div>
      ),
    },
    {
      title: customerText("usedAmount"),
      dataIndex: "usedAmount",
      key: "usedAmount",
      render: (usedAmount) => (
        <p className="text-14 text-[#667085] font-medium">€{usedAmount}</p>
      ),
    },
    {
      title: customerText("promo"),
      dataIndex: "promo",
      key: "promo",
      render: (promo) => (
        <p className="text-14 text-[#101828] font-medium">{promo}</p>
      ),
    },
    {
      title: customerText("usedDate"),
      dataIndex: "usedDate",
      key: "usedDate",
      render: (usedDate) => (
        <p className="text-14 text-[#667085] font-medium">{usedDate}</p>
      ),
    },
  ];

  const usedPromotionsData = [
    {
      key: "1",
      apartment: { name: "Apartment 9B", image: Place1 },
      usedAmount: 25,
      promo: "9B0FF30",
      usedDate: "15/08/2024",
    },
    {
      key: "2",
      apartment: { name: "Apartment 9A", image: Place2 },
      usedAmount: 25,
      promo: "9A0FF30",
      usedDate: "15/08/2024",
    },
    {
      key: "3",
      apartment: { name: "Apartment 8A", image: Place3 },
      usedAmount: 25,
      promo: "8AAA8AAA",
      usedDate: "15/08/2024",
    },
  ];

  return {
    currentPromotionsColumns,
    currentPromotionsData,
    usedPromotionsData,
    usedPromotionsColumns,
  };
};
