import React, { useState } from "react";
import "../../customer/personal-information/index.css";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import Address from "../../customer/personal-information/address";
import GeneralInfo from "../../customer/personal-information/general-info";
import CommunicationPreferences from "../../customer/personal-information/communication-preferences";
import CustomerFooterButtons from "../../customer/customer-footer-buttons";
import CustomerHeader from "../../customer/customer-header";

const AccPersonalInformation = () => {
  const [switchStatus, setSwitchStatus] = useState(false);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country: "",
    street_address: "",
    city: "",
    state: "",
    zip: "",
  });

  const handleChange = (e) => {
    setData((info) => ({ ...info, [e.target.name]: e.target.value }));
  };

  const onSwitchChange = (checked) => {
    setSwitchStatus(checked);
  };

  return (
    <div className="flex flex-1 flex-col gap-5 ">
      <div className="flex flex-col gap-5">
        <div className="bg-white flex flex-col rounded-lg">
          <div className="flex flex-col gap-1 p-6 border-b-[1px] border-[#EAECF0]">
            <CustomerHeader
              title={customerText("personalInfo")}
              description={customerText("personalInfoDescription")}
              showItems={false}
            />
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
            <GeneralInfo
              data={data}
              handleChange={handleChange}
              containerClassNames="!border-0"
            />
            <Address
              data={data}
              handleChange={handleChange}
              containerClassNames="!border-0"
            />
          </div>
          <div className="p-6 border-t-[1px] border-[#EAECF0]">
            <CustomerFooterButtons buttonColor={"#6DDFE2"} />
          </div>
        </div>
      </div>
      <CommunicationPreferences
        switchStatus={switchStatus}
        onSwitchChange={onSwitchChange}
        title={customerText("marketingCommunication")}
        description={customerText("marketingCommunicationDescription")}
        theme={{
          phoneIconBgColor: "#6DDFE2",
          inboxIconBgColor: "#6DDFE2",
          switchClassName: "Acc-switch",
        }}
      />
      <CommunicationPreferences
        switchStatus={switchStatus}
        onSwitchChange={onSwitchChange}
        title={customerText("promotionCommunication")}
        description={customerText("promotionCommunicationDescription")}
        theme={{
          phoneIconBgColor: "#6DDFE2",
          inboxIconBgColor: "#6DDFE2",
          switchClassName: "Acc-switch",
        }}
      />
    </div>
  );
};

export default AccPersonalInformation;
