import React, { useEffect, useState } from "react";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
// import { NotificationManager } from "react-notifications";
// import WhiteLabelService from "../../services/whiteLabelService";
import "./login.css";
import { t } from "i18next";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import WhiteLabelService from "../../../services/whiteLabelService";
import { NotificationManager } from "react-notifications";
import { APP_SET_LOGIN_USER } from "../../../redux/actions/types";
import { useDispatch } from "react-redux";
import { KEYS, setStorageKey } from "../../../utils/storage";

const validator = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .max(72, "Max exceeded")
    .min(5, "Min not met")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  password: yup
    .string()
    .trim()
    .required("Password is required")
    .max(250, "Max exceeded")
    .min(6, "Min not met"),
});

export function decodePathname(pathname) {
  return pathname.replace(/%20/g, " ");
}

const AccLogin = () => {
  const { app_key = "BY%206547APPF" } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const dispatch = useDispatch();
  const formikProps = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validator,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });
  const accToken = localStorage.getItem("accToken");

  const redirect = searchParams.get("redirect");

  useEffect(() => {
    if (accToken) {
      if (redirect) {
        navigate(redirect);
      } else {
        navigate(`/accommodation/customer/personal-info`);
      }
    }
  }, [accToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.sessionStorage.clear();
  }, []);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleChange = (e) => {
    formikProps.setFieldValue([e.target.name], e.target.value);
  };

  const handleLogin = () => {
    const payload = { ...formikProps.values, source: "bb_apartments" };
    WhiteLabelService.bybestLogin(payload, app_key)
      .then((res) => {
        NotificationManager.success(customerText("loginSuccess"));
        setStorageKey(KEYS.USER_DATA, { ...res.user, isAffilate: false });
        dispatch({
          type: APP_SET_LOGIN_USER,
          payload: { ...res.user, isAffilate: false },
        });
        localStorage.setItem("accToken", res?.access_token);

        if (redirect) {
          navigate(redirect);
        } else {
          navigate(`/accommodation/customer/personal-info`);
        }
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message || t("contact_us.somethingWentWrong"),
          customerText("error"),
          3000
        );
      });
  };

  const onRegister = () => {
    navigate("/register");
  };

  return (
    <div
      style={{ height: "calc(100vh - 118px)" }}
      className="w-full py-5 md:py-20 px-6 flex flex-col align-col-middle !bg-[#f3f3f3] main-signin-wrapper"
    >
      <div className="p-6 w-full md:w-[440px] rounded-[4px] border bg-white border-[#00000020]">
        <h1 className="text-16 font-bold leading-[24px] text-[#000]">
          {customerText("login")}
        </h1>
        <p className="text-12 leading-[18px] text-[#000]">
          {customerText("loginToYourAccount")}
        </p>
        <div className="mt-3">
          <p className="text-12 leading-[18px] text-[#000]">
            {customerText("email")}
          </p>
          <input
            name="email"
            type="email"
            value={formikProps.values.email}
            error={formikProps.errors.email}
            onChange={handleChange}
            className="mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400"
          />
          <p className="mt-3 text-12 leading-[18px] text-[#000]">
            {customerText("password")}
          </p>
          <div className="mt-1 w-full h-[38px] px-5 border-[#CED4DA] border-[1px] rounded-[4px] flex align-middle focus:border-blue-400 ">
            <input
              type={type}
              name="password"
              value={formikProps.values.password}
              error={formikProps.errors.password}
              onChange={handleChange}
              autoComplete="current-password"
              className="flex-1 border-none focus-visible:border-none focus-visible:outline-none"
            />
            <span
              className="flex justify-around items-center"
              style={{ color: "#CBD5E1" }}
              onClick={handleToggle}
            >
              <Icon class="absolute " icon={icon} size={18} />
            </span>
          </div>
          {/* <div className="w-full flex justify-end items-end mt-3">
            <div className="text-[#000] text-[14px] leading-[21px] underline cursor-pointer">
              {customerText("forgotPassword")}
            </div>
          </div> */}

          <button
            className="mt-5  text-white text-[14px] leading-[21px] font-medium bg-[#000]  rounded-[4px] h-[41px] w-full"
            onClick={handleLogin}
          >
            {customerText("login")}
          </button>
          {/* <div className="mt-4 w-full flex justify-center items-center ">
            <p className="text-[#000] font-medium text-[14px] leading-[21px]">
              {customerText("noAccount")}
            </p>
            <button
              className="px-2 h-[24px] text-[#000] font-medium text-[14px] leading-[21px] underline"
              onClick={onRegister}
            >
              {customerText("register")}
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AccLogin;
