import React, { Suspense, useEffect } from "react";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ScrollToTop from "../../components/Hooks/scrolltoTop";
import ScrollToTopButtonByBest from "../../components/ScrollToUpButton/scroll-to-top-by-best";
import { useLogout } from "../../services/hooks/use-logout";
import { useMatchMedia } from "../../services/hooks/use-match-media";
import { tokenExpiry } from "../../views/onboarding/common-functions";
import BrandFooter from "../footer/BrandFooter";
import AccommodationHeader from "../header/AccommodationHeader";
const LazyFooter = React.lazy(() => import("../footer/BBAparmentsFooter"));

const AccommodationLayout = () => {
  const accToken = Boolean(localStorage.getItem("accToken"));
  const expire = tokenExpiry();
  const handleLogout = useLogout(false);
  const isMobile = useMatchMedia();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (expire * 1000 < new Date().getTime()) {
      handleLogout();
    }
  }, [expire]);

  useEffect(() => {
    if (!accToken) {
      navigate(`/login`);
    }
  }, [accToken]);

  return (
    <>
      <ScrollToTopButtonByBest buttonClassName={"!bg-[#6DDFE2]"} />
      <AccommodationHeader isAuth={accToken} />
      <div
        className={
          accToken
            ? `${
                isMobile ? "pl-[16px]" : "pl-[306px]"
              } pl-[16px] pr-[16px] pt-4 h-full w-full bg-[#f3f3f3]`
            : ""
        }
        style={{ minHeight: "calc(100vh - 198px)" }}
      >
        <Outlet />
      </div>
      <Suspense fallback={<div>Loading footer...</div>}>
        {accToken ? <LazyFooter /> : <BrandFooter isAccApp={true} />}
      </Suspense>

      <NotificationContainer />
      <ScrollToTop />
    </>
  );
};

export default AccommodationLayout;
