import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerHeader from "../../customer/customer-header";

// TODO: Call Bookings API and map bookings

const ApartmentCard = ({ apartment }) => (
  <div className="border rounded-lg flex gap-2 w-full shadow-lg cursor-pointer">
    <div className="w-1/2 sm:w-1/4 aspect-square rounded-lg m-3 overflow-hidden">
      <img
        src={apartment.rental_unit_photo}
        alt={apartment.rental_unit_name}
        className="object-cover"
      />
    </div>
    <div className="p-4">
      <h3 className="text-xl font-semibold">{apartment.rental_unit_name}</h3>
      <p className="text-sm text-[#667085] mt-2">
        {dayjs(apartment.check_in).format("DD")}
        {"-"}
        {dayjs(apartment.check_out).format("DD")}{" "}
        {dayjs(apartment.check_out).format("MMM")}{" "}
        {dayjs(apartment.check_out).format("YYYY")} • {apartment.total}{" "}
        {apartment.currency}
      </p>
      <div className="flex items-center gap-2 mt-2">
        <span className="px-2 py-1 bg-[#ECFDF3] text-[#12B76A] text-xs font-medium rounded-lg">
          {/* {customerText(apartment.status)} */}
          {apartment.status}
        </span>
      </div>
      <div className="mt-4 w-full py-2 px-3 text-white bg-[#6DDFE2] rounded-lg font-semibold">
        {customerText("rebook_appartment")}
      </div>
    </div>
  </div>
);

const AccBookings = () => {
  const [bookingsData, setBookingsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);

  const end_user_api_key = "boost-sn-25010xC0R5-enduser!";

  const navigate = useNavigate();

  const [filterValue, setFilterValue] = useState("Past");
  const goToBookingDetails = (row) => {
    if (row) {
      navigate(`${row.id}`, {
        state: row,
      });
    }
  };

  const filterButtons = [
    { label: customerText("active"), value: "Active" },
    { label: customerText("past"), value: "Past" },
    { label: customerText("cancelled"), value: "Cancelled" },
  ];

  const fetchBookings = async () => {
    const token = localStorage.getItem("accToken");
    try {
      setIsLoading(true);
      setIsError(false);
      setError(null);
      const res = await fetch(
        `https://core.venueboost.io/api/v1/end-user/bookings?SN_BOOST_CORE_ENDUSER_API_KEY=${end_user_api_key}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      setBookingsData(data);
    } catch (error) {
      setIsError(true);
      setError(error.message);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  return (
    <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
      <div className="relative flex flex-col w-full p-6">
        <div className="col-span-9">
          <CustomerHeader
            description={customerText("view_and_Manage_booking_history")}
            numberOfItems={bookingsData?.Unknown?.total}
            items={customerText("bookingsSm")}
            title={customerText("bookings")}
          />
        </div>
        <div className="flex gap-3 mt-4">
          {filterButtons.map((item) => (
            <div
              key={item.value}
              onClick={() => {
                setFilterValue(item.value);
              }}
              className={`cursor-pointer px-4 py-2 text-sm rounded-md border-[1px] border-[#D0D5DD] ${
                filterValue === item.value
                  ? "!border-[#6DDFE2] bg-[#6DDFE236]"
                  : ""
              } `}
            >
              {item.label}
            </div>
          ))}
        </div>
        {isLoading ? (
          <p>Loading</p>
        ) : isError ? (
          <p>{error.message}</p>
        ) : bookingsData !== null ? (
          <BookingsData
            bookingsData={bookingsData}
            onClick={goToBookingDetails}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AccBookings;

function BookingsData({ bookingsData, onClick }) {
  const periods = bookingsData?.Unknown?.periods;
  const mappablePeriods = periods
    ? Object.entries(periods).map(([key, value]) => {
        return { month: key, ...value };
      })
    : [];

  return (
    <>
      {mappablePeriods.map((period) => (
        <div className="py-4 mt-6 border-t-[1px]">
          <div className="mb-6">
            <h2 className="text-xl font-semibold">Albania</h2>
            <p className="text-sm text-[#667085]">
              {`16-18 ${dayjs(period.month).format("MMM YYYY")}`}
            </p>
          </div>
          <div className="grid sm:grid-cols-2 grid-cols-1 flex-wrap gap-6">
            {period.bookings.map((booking) => (
              <div
                key={booking.id}
                onClick={() => {
                  onClick(booking);
                }}
              >
                <ApartmentCard key={booking.id} apartment={booking} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}
