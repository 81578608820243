import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import confirmed_success from "../../assets/svgs/confirmed_success.svg";

const BookingSuccessScreen = ({ fullName }) => {
  const [date, setDate] = useState("");
  const { state } = useLocation();

  useEffect(() => {
    setDate(moment(new Date()).format("MMMM Do YYYY, h:mm:ss a"));
  }, []);

  return (
    <div className="flex flex-col justify-center rounded-[13px] mt-8 pt-8 pb-4">
      <div className="flex flex-col w-full mt-8 justify-center items-center">
        <img
          src={confirmed_success}
          alt="Confirmation Icon"
          className="w-[100px] h-[100px] py-4"
        />
        <p className="text-black1 text-[16px] font-bold">
          Booking Successfully Placed
        </p>
        <p className="text-black1 font-bold mt-4 mb-2">Booking Date</p>
        <p className="mb-4">
          <span className="text-gray1">CheckIn: {state?.checkInDate}</span>
        </p>
        <p className="mb-4">
          <span className="text-gray1">CheckOut: {state?.checkOutDate}</span>
        </p>
        <p className="text-black1 font-bold mt-4 mb-2">Number of Guests</p>
        <p className="mb-4">
          <span className="text-gray1">{state?.guest_num} Guests</span>
        </p>
        <div className="flex flex-col justify-center items-center text-[13px] text-cyan1 bg-[#ECFDF3] border-[#ECFDF3] border align-center rounded-[10px] px-12 py-4 mb-4">
          <div className="flex justify-center items-center text-[12px] font-normal text-black1">
            A receipt email will be sent to you shortly, including the
            transaction details for the booking.
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSuccessScreen;
