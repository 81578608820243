import React from "react";
import "./index.css";
import CustomerHeader from "../customer-header";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

const LoginSecurityActivities = () => {
  const loginLabels = [
    `${customerText(
      "loginOn"
    )} 3 July 2024 4:32 AM, IP 192.168.1.1 , ${customerText(
      "from"
    ).toLowerCase()} Windows 11 `,
    `${customerText("receivedEmail")} 3 July 2024 4:32 AM`,
    `${customerText("recentOrder")} 3 July 2024 4:32 AM`,
  ];

  return (
    <div className="login-security">
      <CustomerHeader
        title={customerText("loginSecurityAndActivitiesTitle")}
        description={customerText("lastLoginNow")}
        showItems={false}
        showHome={false}
      />
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] w-full " />
      <span className="activities">{customerText("activitiesCapital")}</span>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] w-full " />
      {loginLabels.map((item, index) => (
        <>
          <span className="login-item">{item}</span>
          {index !== loginLabels.length - 1 && (
            <hr className="border-[1px] hidden md:block border-[#E5E7EB] w-full " />
          )}
        </>
      ))}
    </div>
  );
};

export default LoginSecurityActivities;
